<template>
  <div style="background-color: #ebf0ff">
    <v-layout wrap justify-center>
      <v-flex xs12>
        <span style="
              font-family: montserratExtraBold;
              font-size: 22px;
              color: #000000;
            ">Green Bond / Green Bond Programme</span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-10>
      <v-flex xs10>
        <v-layout wrap pb-5>
          <v-flex xs12 sm12 md12 pa-2>
            <v-layout wrap justify-start>
              <v-flex xs12 sm2 text-left>
                <span style="
                      font-family: montserratSemiBold;
                      color: #000000;
                      font-size: 14px;
                    ">ISSUER NAME :
                </span>
              </v-flex>
              <v-flex xs12 sm10 text-left>
                <v-text-field :hide-details="true" outlined v-model="bond.issuerName" dense></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm12 md12 pa-2>
            <v-layout wrap justify-start>
              <v-flex xs12 sm4 text-left>
                <span style="
                      font-family: montserratSemiBold;
                      color: #000000;
                      font-size: 14px;
                    ">Date of completion or of latest update :</span>
              </v-flex>
              <v-flex xs12 sm8 text-left>
                <v-text-field :hide-details="true" outlined v-model="bond.date" dense></v-text-field>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
        <v-tabs v-model="tabValue" color="#7CB9E8" grow dark center-active centered :show-arrows="true"
          active-class="activ" class="nonactiv" background-color="#ebf0ff" style="font-family: robotoRegular">
          <v-tab style="color: #000" dark href="#tab-1">section 1</v-tab>
          <v-tab style="color: #000" dark href="#tab-2">section 2</v-tab>
          <v-tab style="color: #000" dark href="#tab-3">section 3</v-tab>
          <v-tab style="color: #000" dark href="#tab-4">Section 4</v-tab>
          <v-tab-item value="tab-1">
            <v-card flat style="background-color: #ebf0ff">
              <v-layout wrap>
                <v-flex xs12>
                  <IssuanceOverview />
                </v-flex>
              </v-layout>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-card flat style="background-color: #ebf0ff">
              <v-layout wrap>
                <v-flex xs12>
                  <IssuerSustainability />
                </v-flex>
              </v-layout>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-3">
            <v-card flat style="background-color: #ebf0ff">
              <v-layout wrap>
                <v-flex xs12>
                  <GreenBondPrinciples />
                </v-flex>
              </v-layout>
            </v-card>
          </v-tab-item>
          <v-tab-item value="tab-4">
            <v-card flat style="background-color: #ebf0ff">
              <v-layout wrap>
                <v-flex xs12 pt-5>
                  <AdditionalInfo></AdditionalInfo>
                </v-flex>
              </v-layout>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import IssuanceOverview from "./issuanceOverview";
import IssuerSustainability from "./issuerSustainability";
import GreenBondPrinciples from "./greenBondPrinciples";
import AdditionalInfo from "./additionalInfo";
export default {
  components: {
    IssuanceOverview,
    IssuerSustainability,
    GreenBondPrinciples,
    AdditionalInfo,
  },
  data: () => ({
    appLoading: false,
    ServerError: false,
    bond: {
      issuerName: "",
      date: "",
    },
    tabValue: "tab-1",
    items: ["section 1", "section 2", " section 3", "section 4"],
  }),
  methods: {},
};
</script>